import styled from "styled-components"
import MainBg from "../../assets/images/main_bg.png"

export const Container = styled.div`
  max-width: 100vw;
  width: 100%;
  height: 100vh;
  background-color: ${props => props.theme.color.primary};
  background-image: url(${MainBg});
  background-repeat: no-repeat;
  background-position: top right;
  background-size: contain;
  padding-top: 120px;
  min-height: 650px;
  overflow: hidden;
  @media screen and (max-width: ${props =>
      props.theme.breakpoints.laptopStandard}) {
    padding-top: 79px;
    min-height: 600px;
  }
  @media screen and (max-width: ${props => props.theme.breakpoints.tablet}) {
    padding-top: 50px;
    min-height: 500px;
  }
  @media screen and (max-width: 650px) {
    min-height: initial;
    height: auto;
  }
  &.short {
    height: 120px;
    min-height: 120px;
    @media screen and (max-width: ${props =>
        props.theme.breakpoints.laptopStandard}) {
      height: 80px;
      min-height: 80px;
    }
    @media screen and (max-width: ${props => props.theme.breakpoints.tablet}) {
      height: 50px;
      min-height: 50px;
    }
    @media screen and (max-width: 650px) {
      min-height: initial;
      height: auto;
    }
  }
`

export const Wrapper = styled.div``
